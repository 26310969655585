import { Collapse, Space, Typography} from 'antd';
import React from 'react';
const { Paragraph, Text } = Typography;
const { Panel } = Collapse;

const ValidationErrors = ({ errors }: { errors: any }) => {

    const extractErrors = (jsonResponse: any) => {
        const errors = [];

        // Iterate over the JSON structure
        for (const teamName in jsonResponse) {
            if(["metadata", "general"].includes(teamName)){
                errors.push(jsonResponse[teamName]);
                continue
            }

            const team = jsonResponse[teamName];
            for (const appName in team) {
                if(["metadata", "general"].includes(appName)){
                    if(Array.isArray(team[appName])) {
                        for (const error of team[appName]) {
                            errors.push(`${error}`);
                        }
                    }
                    else {
                        errors.push(team[appName]);
                    }
                    continue
                }

                const app = team[appName];
                for (const serviceType in app) {
                    const service = app[serviceType];
                    //non-field specific error messages
                    if (["metadata", "general"].includes(serviceType)) {
                        errors.push(service);
                        if(Array.isArray(service)) {
                            for (const error of service) {
                                errors.push(`${appName} - ${serviceType} - ${error}`);
                            }
                        }
                        else {
                            errors.push(service);
                        }
                        continue
                    }
                    if("state" in service){
                        errors.push(`${appName} - ${serviceType} - ${service["state"]}`);
                        continue
                    }

                    for (const serviceItem in service) {
                        const serviceItemFields = service[serviceItem];
                        for (const serviceItemField in serviceItemFields) {
                            errors.push(`${appName} - ${serviceItem} - ${serviceItemFields[serviceItemField]}`);

                        }
                    }
                }
            }
        }

        return errors;
    }

    const generateList = (errors: any) => {
        const extractedErrors = extractErrors(errors)

        return (
            <Paragraph>
                <ul style={{textAlign:"left"}}>
                    {extractedErrors.map(err => <li>{err}</li>)}
                </ul>
            </Paragraph>
        )
    }
    return (
        <Space direction="vertical" style={{margin: "5px"}}>
            <Collapse bordered={false} style={{backgroundColor: "#fff2f0", borderColor:"#ffccc7", width: "500px", marginBottom:"5px"}}>
            <Panel header={<><Text>Validation Failed</Text></>} key="1">
                    {generateList(errors)}
                </Panel>
            </Collapse>
        </Space>
    );
};

export default ValidationErrors;
